import React from 'react';
import { Button, Header, Icon, Image, Modal, Segment, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import cx from 'classnames';
import ReactPlayer from 'react-player';
import VideoModal from '../VideoModal';
import PromoModal from '../PromoModal';

import MainFrame from '../MainFrame';

import './news.css';

const propTypes = {
}

const defaultProps = {
}

const getProgramContent = () => {
  return (
    <div className="promo-pogramme">
      <h1>Présidentielle 2020</h1>
      <div>
        <div className="promo-programme-text">
          <a target="_blank" href="assets/pdf/Programme Présidentiel de Kadré Désiré OUEDRAOGO - Aperçu.pdf">
            Consultez le programme de Kadré Désiré OUEDRAOGO
          </a> 
        </div>
        <div className="promo-programme-image">
          <a target="_blank" href="assets/pdf/PROGRAMME de KDO 28 OCTOBRE 2020.pdf">
            <img src="assets/pdf/programme_kadre_desire_ouedraogo_apercu.png" />
          </a> 
        </div>
        <div className="promo-programme-fineprint">
          <a target="_blank" href="assets/pdf/PROGRAMME de KDO 28 OCTOBRE 2020.pdf">
            Voir l'intégralité
          </a> 
        </div>
      </div>
    </div>
  );
};

const get2021Whishes = () => {
  return (
    <div className="promo-pogramme c-textual">
      <h1>VŒUX de nouvel an 2021</h1>
      <div>
        <div className="promo-programme-text-light">
          <p>
            A l’orée de la nouvelle année 2021, j’ai le grand plaisir et l’honneur d’adresser à tous mes compatriotes et aux amis du Burkina Faso, mes vœux les plus chaleureux de bonheur et de prospérité.
          </p>
          <p>
            L’année 2020 a été une année de grands défis pour notre pays. A la situation sécuritaire délétère, est venu s’ajouter le fléau de la pandémie de COVID-19 qui continue d’endeuiller de nombreuses familles.
          </p>
          <p>
            J’adresse à tous ceux et celles qui ont perdu un être cher durant l’année écoulée, mes sincères condoléances et ma totale compassion.
          </p>
          <p>
            Les élections couplées de novembre 2020 étaient une échéance cruciale pour notre peuple appelé à choisir ses dirigeants pour les cinq prochaines années. Grâce à la clairvoyance et à la sagesse de tous, notre pays a pu éviter une crise postélectorale qui pouvait naitre de ce scrutin émaillé d’irrégularités et de dysfonctionnements auxquels la CENI ne nous a pas habitués les 20 dernières années. Je souhaite que le sacrifice et le renoncement des uns et des autres servent à apaiser une situation de tension sociale et de conflits divers non résolus.
          </p>
          <p>
            Notre pays a besoin d’une réconciliation vraie et de l’engagement de tous à emprunter de nouveaux chemins de droiture et de probité, faute de quoi il sera difficile de corriger les dérives constatées dans notre processus électoral et dans notre société en général. Il faut un véritable changement de mentalité et un retour aux valeurs cardinales que nous ont légués nos ancêtres.
          </p>
          <p>
            J’invite nos concitoyens à la vigilance pour conforter notre processus démocratique et notre vivre ensemble.
          </p>
          <p>
            Bonne et heureuse année 2021 à toutes et à tous !
          </p>
          <br/>
          <p>Kadré Désiré Ouédraogo</p>
          <p>Grand-Croix de l’Ordre National</p>
        </div>
      </div>
    </div>
  );
};
  
const getDenisOuedraogoCondolences = () => {
  return (
    <div className="promo-pogramme c-textual">
      <h1>Repose en paix</h1>
      <div>
        <div className="promo-programme-text-light">
          <p>
            <img src="assets\img\nouvelles_denis_ouedraogo.jpg" alt="" className="img-fluid article-img" />
            Ce jour, j'ai accompagné à sa dernière demeure Denis Ouédraogo décédé le 2 juin 2021. Denis fut mon Directeur de cabinet lorsque j'étais Premier Ministre, puis Président de la Commission de la CEDEAO. C'est une grande tristesse pour moi car Denis était l'incarnation de la gentillesse, de la disponibilité et de la charité.  Ouvert à tous, grands comme petits, il était toujours présent pour rendre service.  Toujours jovial, il ne voyait dans son vis-à-vis que ce qui était bon en lui. Cette tolérance lui permettait d'être l'ami de tous.
          </p>
          <p>
            Pour sa grande famille dont il était l'aîné de 10 frères et sœurs, il a toujours été un frère prévenant et secourable. Il a servi le Burkina Faso avec compétence et droiture durant sa longue carrière de diplomate. C'est d'ailleurs au cours de cette carrière que nos chemins se sont croisés pour la première fois voilà plus de trente ans à Lagos au Nigeria. Depuis, il a toujours été à mes côtés aux jours de joie comme aux jours sombres. Je lui exprime ma reconnaissance pour cette amitié. 
          </p>
          <p>
            À sa famille éplorée, j’adresse mes condoléances les plus attristées. Plus particulièrement à son épouse Bernadette, à ses enfants Gislain, Carine et Godwill, j'exprime ma compassion et ma solidarité dans ce moment difficile. Que Dieu leur donne la force et le courage de supporter la douleur de la séparation. Cher Denis, repose en paix et que le Seigneur fasse briller sur toi la lumière de sa face.
          </p>
          <br/>
          <p>Kadré Désiré Ouédraogo</p>
        </div>
      </div>
    </div>
  );
};

const getSolhanAttackReactionMessage = () => {
  return (
    <div className="promo-pogramme c-textual">
      <div>
        <div className="promo-programme-text-light">
          <p>
            Face à l'effroyable drame de Solhan que je condamne avec la dernière énergie, j'adresse mes condoléances les plus émues aux familles éplorées et mon soutien aux forces de défense et de sécurité. Je présente à la nation Burkinabè toute entière mes condoléances. Ce drame nous interpelle sur la nécessité de retrouver le plus rapidement possible le chemin de la paix et de la sécurité. Nous pouvons le faire en changeant nos comportements et en servant avec sincérité et dévouement la cause de notre patrie. Que Dieu bénisse le Burkina Faso.
          </p>
          <br/>
          <p>Kadré Désiré Ouédraogo</p>
        </div>
      </div>
    </div>
  );
};


const NewsContent = (props) => {
  return (
  <MainFrame
    displayMainHeader={false}
    contentComponent={(
      <div>

        <section id="news" className="blog-mf sect-pt4 route">
          <div className="container newsContainer">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Nouvelles
                  </h3>
                  <p className="subtitle-a">
                    Suivez l'actualité sur KDO
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            
            {/*--- FIRST ROW ---*/}
            <div className="row">
            
            <div className="col-md-3">
              <div className="card card-blog">
                <div className="card-img">
                  <PromoModal
                      triggerComponent={
                        (<a href="javascript:void(0);"><img src="assets\img\nouvelles_attaques_solhan.png" alt="" className="img-fluid" /></a>)
                      }
                      className="scrolling-modal"
                      promo={getSolhanAttackReactionMessage()}
                  />
                </div>
                <div className="card-body">
                  <div className="card-category-box">
                    <div className="card-category">
                      <h6 className="category">Attaques de Solhan</h6>
                    </div>
                  </div>
                  
                  <PromoModal 
                    triggerComponent={
                      (<h3 className="card-title"><a href="javascript:void(0);">Je présente à la nation Burkinabè toute entière mes condoléances</a></h3>)
                    }
                    className="scrolling-modal"
                    promo={getSolhanAttackReactionMessage()}
                  />
                  <p className="card-description">
                    Ce drame nous interpelle sur la nécessité de retrouver le plus rapidement possible le chemin de la paix et de la sécurité.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="post-author">
                    <PromoModal 
                      triggerComponent={
                        (<a href="javascript:void(0);">
                          <span className="author">
                            Voir l'intégralité
                          </span>
                      </a>)
                      }
                      className="scrolling-modal"
                      promo={getSolhanAttackReactionMessage()}
                    />
                  </div>
                  <div className="post-date">
                    <span className="ion-ios-clock-outline"></span> 07 Juin 2021
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="card card-blog">
                <div className="card-img">
                  <PromoModal
                      triggerComponent={
                        (<a href="javascript:void(0);"><img src="assets\img\nouvelles_denis_ouedraogo.jpg" alt="" className="img-fluid" /></a>)
                      }
                      className="scrolling-modal"
                      promo={getDenisOuedraogoCondolences()}
                  />
                </div>
                <div className="card-body">
                  <div className="card-category-box">
                    <div className="card-category">
                      <h6 className="category">Denis Ouedraogo</h6>
                    </div>
                  </div>
                  
                  <PromoModal 
                    triggerComponent={
                      (<h3 className="card-title"><a href="javascript:void(0);">Il a servi le Burkina Faso avec compétence et droiture.</a></h3>)
                    }
                    className="scrolling-modal"
                    promo={getDenisOuedraogoCondolences()}
                  />
                  <p className="card-description">
                    Cher Denis, repose en paix et que le Seigneur fasse briller sur toi la lumière de sa face.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="post-author">
                    <PromoModal 
                      triggerComponent={
                        (<a href="javascript:void(0);">
                          <span className="author">
                            Voir l'intégralité
                          </span>
                      </a>)
                      }
                      className="scrolling-modal"
                      promo={getDenisOuedraogoCondolences()}
                    />
                  </div>
                  <div className="post-date">
                    <span className="ion-ios-clock-outline"></span> 07 Juin 2021
                  </div>
                </div>
              </div>
            </div>

              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                    <PromoModal
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_voeux_2021.png" alt="" className="img-fluid" /></a>)
                        }
                        className="scrolling-modal"
                        promo={get2021Whishes()}
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">VŒUX de nouvel an 2021</h6>
                      </div>
                    </div>
                    
                    <PromoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Voeux pour l'année 2021</a></h3>)
                      }
                      className="scrolling-modal"
                      promo={get2021Whishes()}
                    />
                    <p className="card-description">
                      Kadré Désiré OUEDRAOGO adresse à ses compatriotes et aux amis du Burkina Faso ses voeux les plus sincères.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 31 Décembre 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                    <PromoModal
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_programme_kdo.jpg" alt="" className="img-fluid" /></a>)
                        }
                        promo={getProgramContent()}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Programme de KDO</h6>
                      </div>
                    </div>
                    <PromoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Consultez le programme de Kadré Désiré OUEDRAOGO</a></h3>)
                      }
                      promo={getProgramContent()}
                    />
                    <p className="card-description">
                      Kadré Désiré OUEDRAOGO expose son programme pour un Burkina Faso uni et prospère.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <a target="_blank" href="assets/pdf/PROGRAMME de KDO 28 OCTOBRE 2020.pdf">
                        <span className="author">
                          Voir l'intégralité
                        </span>
                      </a>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 3 novembre 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_agir_ensemle_programme.png" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/projet-de-soci%C3%A9t%C3%A9/733589433901980/?__so__=channel_tab&__rv__=related_videos`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Message de KDO</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Programme de KDO</a></h3>)
                      }
                      videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/projet-de-soci%C3%A9t%C3%A9/733589433901980/?__so__=channel_tab&__rv__=related_videos`}
                    />
                    <p className="card-description">
                      Écoutez Kadré Désiré OUEDRAOGO vous donner les grandes lignes de son Programme pour le Burkina Faso.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/projet-de-soci%C3%A9t%C3%A9/733589433901980/?__so__=channel_tab&__rv__=related_videos`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 5 novembre 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_agir_ensemle_unis.png" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/2807741586218270/?__so__=channel_tab&__rv__=all_videos_card`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Message de KDO</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Un autre avenir pour le Burkina Faso</a></h3>)
                      }
                      videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/2807741586218270/?__so__=channel_tab&__rv__=all_videos_card`}
                    />
                    <p className="card-description">
                      Kadré Désiré OUEDRAOGO se présente à vous et appel à l'unité.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/2807741586218270/?__so__=channel_tab&__rv__=all_videos_card`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 31 octobre 2020
                    </div>
                  </div>
                </div>
              </div>

             <div className="col-md-3">
               <div className="card card-blog">
                 <div className="card-img">
                     <VideoModal 
                       triggerComponent={
                         (<a href="javascript:void(0);"><img src="assets\img\nouvelles_lefaso_tv.jpg" alt="" className="img-fluid" />
                         <div className="news-video-vail"></div>
                         <i className="fa fa-youtube-play fa-5x"></i></a>)
                       }
                       videoUrl={`https://www.youtube.com/watch?v=TI52UE0iDho`}
                     />
                 </div>
                 <div className="card-body">
                   <div className="card-category-box">
                     <div className="card-category">
                       <h6 className="category">Le Faso TV</h6>
                     </div>
                   </div>
                   <VideoModal 
                     triggerComponent={
                       (<h3 className="card-title"><a href="javascript:void(0);">"Gouverner par l'exemple" : Le Faso TV reçoit Kadré Désiré OUEDRAOGO</a></h3>)
                     }
                     videoUrl={`https://www.youtube.com/watch?v=TI52UE0iDho`}
                   />
                   <p className="card-description">
                     Écoutez KDO répondre aux questions de Cryspin LAOUNDIKI et de Oumar OUEDRAOGO sur sa vision pour le Burkina.
                   </p>
                 </div>
                 <div className="card-footer">
                   <div className="post-author">
                     <VideoModal 
                       triggerComponent={
                         (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                       }
                       videoUrl={`https://www.youtube.com/watch?v=TI52UE0iDho`}
                     />
                   </div>
                   <div className="post-date">
                     <span className="ion-ios-clock-outline"></span> 13 novembre 2020
                   </div>
                 </div>
               </div>
             </div>

             <div className="col-md-3">
               <div className="card card-blog">
                 <div className="card-img">
                     <VideoModal 
                       triggerComponent={
                         (<a href="javascript:void(0);"><img src="assets\img\nouvelles_savane.png" alt="" className="img-fluid" />
                         <div className="news-video-vail"></div>
                         <i className="fa fa-youtube-play fa-5x"></i></a>)
                       }
                       videoUrl={`https://www.facebook.com/savanetv/videos/3442564929156945/?__so__=channel_tab&__rv__=all_videos_card`}
                     />
                 </div>
                 <div className="card-body">
                   <div className="card-category-box">
                     <div className="card-category">
                       <h6 className="category">Savane TV</h6>
                     </div>
                   </div>
                   <VideoModal 
                     triggerComponent={
                       (<h3 className="card-title"><a href="javascript:void(0);">Savane TV reçoit Kadré Désiré Ouédraogo</a></h3>)
                     }
                     videoUrl={`https://www.facebook.com/savanetv/videos/3442564929156945/?__so__=channel_tab&__rv__=all_videos_card`}
                   />
                   <p className="card-description">
                     Écoutez KDO échanger avec Denise KOULIBALY et Soumïla RABO sur son plan pour le Burkina Faso.
                   </p>
                 </div>
                 <div className="card-footer">
                   <div className="post-author">
                     <VideoModal 
                       triggerComponent={
                         (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                       }
                       videoUrl={`https://www.facebook.com/savanetv/videos/3442564929156945/?__so__=channel_tab&__rv__=all_videos_card`}
                     />
                   </div>
                   <div className="post-date">
                     <span className="ion-ios-clock-outline"></span> 6 novembre 2020
                   </div>
                 </div>
               </div>
             </div>
             
              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_grand_deballage.jpg" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=PqT6d8_FzMA`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Le Grand Déballage</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Le Grand Déballage avec Kadré Désiré Ouédraogo</a></h3>)
                      }
                      videoUrl={`https://www.youtube.com/watch?v=PqT6d8_FzMA`}
                    />
                    <p className="card-description">
                      Écoutez KDO répondre aux questions du Grand Déballage et présenter sa vision pour le Burkina Faso.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=PqT6d8_FzMA`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 15 décembre 2019
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_surface_verite.jpg" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=O1GaZbH5nrg`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Surface de vérité avec KDO</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Aubin GUEBRE reçoit KDO sur l'émission "Surface de vérité"</a></h3>)
                      }
                      videoUrl={`https://www.youtube.com/watch?v=O1GaZbH5nrg`}
                    />
                    <p className="card-description">
                      Écoutez Aubin GUEBRE échanger avec Kadré Désiré OUEDRAOGO sur sa vision pour le Burkina.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=O1GaZbH5nrg`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 26 mars 2019
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                    <a target="_blank" href="https://www.iburkina.com/2020/07/mouvement-agir-ensemble-solidaire-des-deguerpis-de-yale-a-leo/"><img src="assets/img/nouvelles_agir_ensemble_solidaire_yale_leo.jpg" alt="" className="img-fluid" /></a>
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Léo</h6>
                      </div>
                    </div>
                    <h3 className="card-title"><a target="_blank" href="https://www.iburkina.com/2020/07/mouvement-agir-ensemble-solidaire-des-deguerpis-de-yale-a-leo/">Mouvement Agir Ensemble : solidaire des déguerpis de Yalé à Léo</a></h3>
                    <p className="card-description">
                      Le Mouvement Agir Ensemble pour le Burkina Faso, s’élève contre la violence injustifiée des agents des eaux et Forêts et la condamne sans aucune ambiguïté.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <span className="author"><a target="_blank" href="https://www.iburkina.com/2020/07/mouvement-agir-ensemble-solidaire-des-deguerpis-de-yale-a-leo/">Lire la suite...</a></span>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 25 juin 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                    <VideoModal 
                          triggerComponent={
                            (<a href="javascript:void(0);"><img src="assets/img/nouvelles_rtb_sur_la_breche.png" alt="" className="img-fluid" />
                            <div className="news-video-vail"></div>
                            <i className="fa fa-youtube-play fa-5x"></i></a>)
                          }
                          videoUrl={`https://www.youtube.com/watch?v=SLZi2sASmBg`}
                        />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">RTB</h6>
                      </div>
                    </div>
                    <h3 className="card-title">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);">RTB - Sur la Brèche avec Kadré Désiré OUEDRAOGO</a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=SLZi2sASmBg`}
                      />
                    </h3>
                    <p className="card-description">
                      KDO est l'invité de l'émission "Sur la Brèche" de la Radiodiffusion de Télévision du Burkina (RTB). KDO répond à plusieurs questoins portant sur son parcours et sa vision.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <span className="author">
                        <VideoModal 
                          triggerComponent={
                            (<a href="javascript:void(0);">Voir la vidéo...</a>)
                          }
                          videoUrl={`https://www.youtube.com/watch?v=SLZi2sASmBg`}
                        />
                      </span>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 6 juin 2020
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                    <a target="_blank" href={"https://www.facebook.com/KDO2020PRESI/videos/598830997450297/"}><img src="assets/img/nouvelles_discours_investiture.png" alt="" className="img-fluid" /></a>
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Bobo Dioulasso</h6>
                      </div>
                    </div>
                    <h3 className="card-title"><a target="_blank" href="https://www.facebook.com/KDO2020PRESI/videos/598830997450297/">Discours lors du congrès d'investiture de KDO à Bobo Dioulasso</a></h3>
                    <p className="card-description">
                      C'est dans une salle comble, au Palais De La Culture De Bobo-Dioulasso, que KDO prononce son discours lors de son investiture le dimanche 6 septembre.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <span className="author"><a target="_blank" href="https://www.facebook.com/KDO2020PRESI/videos/598830997450297/">Écouter le discours...</a></span>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 06 septembre 2020
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                    <a target="_blank" href="https://lefaso.net/spip.php?article94938"><img src="assets/img/nouvelles_un_an_apres.jpg" alt="" className="img-fluid" /></a>
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Ouagadougou</h6>
                      </div>
                    </div>
                    <h3 className="card-title"><a target="_blank" href="https://lefaso.net/spip.php?article94938">Déclaration de candidature de KDO à la présidentielle de 2020</a></h3>
                    <p className="card-description">
                      KDO prend l'engagement de répondre à l'appel de la nation pour l'édification d'un Burkina nouveau « … Oui, si Dieu me prête vie, je suis prêt, le moment venu, à être leur candidat aux élections … ».
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <span className="author"><a target="_blank" href="https://lefaso.net/spip.php?article94938">Lire la suite...</a></span>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 16 février 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_aitv_ecowas.jpg" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=ulLoDRHu2nU`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">AIT - Nigéria</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">AIT Interview with ECOWAS President H E Kadre Desire Ouedraogo</a></h3>)
                      }
                      videoUrl={`https://www.youtube.com/watch?v=ulLoDRHu2nU`}
                    />
                    <p className="card-description">
                      Entrevue du Président de la Commission de la CEDEAO, Kadré Désiré OUEDRAOGO.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=ulLoDRHu2nU`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 8 juillet 2015
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>

      </div>
    )
  }
  />);
};

NewsContent.propTypes = propTypes;
NewsContent.defaultProps = defaultProps;

export default NewsContent;