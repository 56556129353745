import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import $ from 'jquery';

const propTypes = {
  className: PropTypes.string,
}

const defaultProps = {
  className: '',
}

const initialContactFormState = Object.freeze({
  formData: {
    contactName: '',
    contactEmail: '',
    contactSubject: '',
    contactMessage: '',
  },
  errors: {},
  reCaptchaVerified: false,
});

const validateForm = (contactFormState, updateContactFormStateFunction) => {
  
  let formValid = true;
  const validationErrors = {};
  // Contact Name
  const contactNameValue = contactFormState.formData[`contactName`];
  if (!contactNameValue || contactNameValue.trim().length < 3) {
    formValid = false;
    validationErrors[`contactName`] = `Veuillez saisir votre nom (au moins 3 caractères)`;
  } else {
    validationErrors[`contactName`] = ``;
  }
  
  // Contact Email
  const contactEmailValue = contactFormState.formData[`contactEmail`];
  const emailRegex = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
  if (!contactEmailValue || !emailRegex.test(contactEmailValue)) {
    formValid = false;
    validationErrors[`contactEmail`] = `Veuillez saisir un email valide`;
  } else {
    validationErrors[`contactEmail`] = ``;
  }
  
  // Contact Subject
  const contactSubjectValue = contactFormState.formData[`contactSubject`];
  if (!contactSubjectValue || contactSubjectValue.length < 4) {
    formValid = false;
    validationErrors[`contactSubject`] = `Veuillez saisir un sujet (au moins 4 caractères)`;
  } else {
    validationErrors[`contactSubject`] = ``;
  }
  
  // Contact Message
  const contactMessageValue = contactFormState.formData[`contactMessage`];
  if (!contactMessageValue) {
    formValid = false;
    validationErrors[`contactMessage`] = `Veuillez saisir votre message`;
  } else if (contactMessageValue.length < 10) {
    formValid = false;
    validationErrors[`contactMessage`] = `Veuillez saisir au moins une phrase complète...`;
  } else {
    validationErrors[`contactMessage`] = ``;
  }

  updateFormFieldErrorMessages(validationErrors, contactFormState, updateContactFormStateFunction);

  return formValid;
};

const getFieldErrorMessage = (fieldName, fieldValue) => {

  switch (fieldName) {
    case `contactName`:
      if (!fieldValue || fieldValue.trim().length < 3) {
        return `Veuillez saisir votre nom (au moins 3 caractères)`;
      }
    case `contactEmail`:
      const emailRegex = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
      if (!fieldValue || !emailRegex.test(fieldValue)) {
        return `Veuillez saisir un email valide`;
      }
    case `contactSubject`:
      if (!fieldValue || fieldValue.trim().length < 4) {
        return `Veuillez saisir un sujet (au moins 4 caractères)`;
      }
    case `contactMessage`:
      if (!fieldValue) {
        return `Veuillez saisir votre message`;
      } else if (fieldValue.length < 10) {
        return `Veuillez saisir au moins une phrase complète...`;
      }
    default: 
      return ``;
  };

};

const updateFormFieldErrorMessages = (errorMessages, contactFormState, updateContactFormStateFunction) => {
  updateContactFormStateFunction({
    ...contactFormState,
    errors: {
      ...errorMessages,
    }
  });
};

const ContactForm = (props) => {
  
  const [contactFormState, updateContactFormStateFunction] = React.useState(initialContactFormState);
  
  const {
    className,
  } = props;

  const handleContactFormInputChange = (e) => {
    const fieldName = get(e, ['target', 'name'], '');
    const fieldValue = get(e, ['target', 'value'], '').trim();
    updateContactFormStateFunction({
      ...contactFormState,
      formData: {
        ...contactFormState.formData,
        [fieldName]: fieldValue
      },
      errors: {
        ...contactFormState.errors,
        [fieldName]: ``,//getFieldErrorMessage(fieldName, fieldValue)
      }
    });
  };

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateForm(contactFormState, updateContactFormStateFunction);
    if (isFormValid && contactFormState.reCaptchaVerified) {
      $('#contact-form-veil').fadeIn();
      $('#contact-submission-state').fadeIn();
      fetch(`${process.env.REACT_APP_KDO_2020_BACKEND_ENDPOINT}/contact/submit`, 
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contactFormState.formData)
      })
      .then(function(response) {
        $('#contact-submission-state').fadeOut();
        $('#contact-form-veil').fadeOut();
        let resultMessage;
        if (response && response.status == 200) {
          resultMessage = `Votre message a bien été envoyé.<br/>Merci !`;
        } else {
          resultMessage = `<span style="color:red;">Une erreur est survenue lors de la soumission de votre message, veuillez réessayer plus tard.</span>`;
        }

        $('#contact-form-confirmation-veil').fadeIn();
        $('#contact-form-submission-result').html(resultMessage);
        $('#contact-form-submission-result').fadeIn();

        setTimeout(() => {
          $('#contact-submission-state').fadeOut();
          $('#contact-form-confirmation-veil').fadeOut();
          $('#contact-form-submission-result').fadeOut();
          resetForm();
        }, 7500);
      })
      .catch(error => {
        handdleSubmissionError(error);
      });
    }
  };

  const handdleSubmissionError = (error) => {
    $('#contact-submission-state').fadeOut();
        $('#contact-form-veil').fadeOut();
        const resultMessage = `<span style="color:red;">Une erreur est survenue lors de la soumission de votre message, veuillez réessayer plus tard.</span>`;

        $('#contact-form-confirmation-veil').fadeIn();
        $('#contact-form-submission-result').html(resultMessage);
        $('#contact-form-submission-result').fadeIn();

        setTimeout(() => {
          $('#contact-submission-state').fadeOut();
          $('#contact-form-confirmation-veil').fadeOut();
          $('#contact-form-submission-result').fadeOut();
        }, 7500);
  };

  const resetForm = () => {
    updateContactFormStateFunction({
      ...initialContactFormState,
      reCaptchaVerified: contactFormState.reCaptchaVerified
    });
    document.getElementById("contact-form").reset();
  };

  const onReCaptchaVerified = (token) => {
    //reCaptcha Verified
    updateContactFormStateFunction({
      ...contactFormState,
      reCaptchaVerified: true
    });
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <GoogleReCaptcha onVerify={token => onReCaptchaVerified(token)} />
      <div id="contact-form-container">
        <form id="contact-form" className={className}>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <input type="text" name="contactName" className="form-control" id="name" placeholder="Votre Nom" data-rule="minlen:4" data-msg="Veuillez saisir au moins 4 caractères" onChange={handleContactFormInputChange} />
                <div className="validate">{contactFormState.errors.contactName}</div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <input type="email" className="form-control" name="contactEmail" id="email" placeholder="Votre Email" data-rule="email" data-msg="Veuillez saisir une email valide" onChange={handleContactFormInputChange} />
                <div className="validate">{contactFormState.errors.contactEmail}</div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <input type="text" className="form-control" name="contactSubject" id="subject" placeholder="Sujet" data-rule="minlen:4" data-msg="Veuillez saisir au moins 8 caractères dans votre sujet" onChange={handleContactFormInputChange} />
                <div className="validate">{contactFormState.errors.contactSubject}</div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <textarea className="form-control" name="contactMessage" rows="5" data-rule="required" data-msg="Veuillez saisir un message" placeholder="Message" onChange={handleContactFormInputChange} ></textarea>
                <div className="validate">{contactFormState.errors.contactMessage}</div>
              </div>
            </div>
            <div className="col-md-12 text-center">
            
            </div>
            <div className="col-md-12 text-center">
              <button type="submit" className="button button-a button-big button-rouded" onClick={handleContactFormSubmit}>Envoyer le Message</button>
            </div>
          </div>
        </form>
        <div id="contact-submission-state">
          <div className="contact-form lds-ripple"><div></div><div></div></div>
        </div>
        <div id="contact-form-submission-result"></div>
        <div id="contact-form-veil"></div>
    </div>
    </GoogleReCaptchaProvider>
  );
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;