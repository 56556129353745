import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import cx from 'classnames';
import ReactPlayer from 'react-player';

const propTypes = {
  triggerComponent: PropTypes.object,
  title: PropTypes.string,
  header: PropTypes.string,
  videoUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
}

const defaultProps = {
  triggerComponent: null,
  title: null,
  header: null,
  videoUrl: null,
  autoPlay: true,
}

const VideoModal = (props) => {
  
  const { triggerComponent, title, header, videoUrl, autoPlay } = props;
  const [open, setOpen] = React.useState(false)
  //const width = (window.screen.width >= 600) ? 30 : 100;
  //const width = `${Math.ceil((window.screen.height) * 0.65)}px`;
  const height = Math.ceil((window.screen.height) * 0.65);
  const width = Math.ceil(height * 1.25);
  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='large'
      trigger={triggerComponent}
    >
      {title && (
        <Header icon>
          {title}
        </Header>)}
      <Modal.Content>
        <ReactPlayer
          width={`90%`}
          height={`${height}px`}
          style={{margin:'0 auto'}}
          url={videoUrl}
          controls={true}
          playing={autoPlay} />
      </Modal.Content>
      <Modal.Actions>
        <div style={{textAlign:'center', margin:'0 auto'}}>
          <Button basic color='red' inverted onClick={() => setOpen(false)}>
            Fermer
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
};

VideoModal.propTypes = propTypes;
VideoModal.defaultProps = defaultProps;

export default VideoModal;