import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import cx from 'classnames';
import ReactPlayer from 'react-player';

const propTypes = {
  triggerComponent: PropTypes.object,
  promo: PropTypes.any,
  className: PropTypes.string,
}

const defaultProps = {
  triggerComponent: null,
  promo: null,
  className: null,
}

const PromoModal = (props) => {
  
  const { promo, triggerComponent, className } = props;
  const [open, setOpen] = React.useState(false)
  const height = Math.ceil((window.screen.height) * 0.65);
  const width = Math.ceil(height * 1.25);
  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
      trigger={triggerComponent}
      className={cx(className, className)}
    >
      <Modal.Content>
        {promo}
      </Modal.Content>
      <Modal.Actions>
        <div style={{textAlign:'center', margin:'0 auto'}}>
          <Button basic color='red' inverted onClick={() => setOpen(false)}>
            Fermer
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
};

PromoModal.propTypes = propTypes;
PromoModal.defaultProps = defaultProps;

export default PromoModal;