import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';



const propTypes = {
  idName: PropTypes.string,
  orientation: PropTypes.string,
}

const defaultProps = {
  idName: '',
  orientation: '',
}

const Timelinr = (props) => {
  
  const {
    idName,
    orientation
  } = props;

  const computedId = `${idName}-${orientation}`;

  return (
    <div id={computedId} className={computedId}>
      <ul id={`${computedId}-dates`}>
        <li><a href={`#${computedId}-dates-2012`} className="selected">2012</a></li>
        <li><a href={`#${computedId}-dates-2001`}>2001</a></li>
        <li><a href={`#${computedId}-dates-1996`}>1996</a></li>
        <li><a href={`#${computedId}-dates-1993`}>1993</a></li>
        <li><a href={`#${computedId}-dates-1985`}>1985</a></li>
        <li><a href={`#${computedId}-dates-1983`}>1983</a></li>
        <li><a href={`#${computedId}-dates-1980`}>1980</a></li>
        <li><a href={`#${computedId}-dates-1977`}>1977</a></li>
      </ul>
      <ul id={`${computedId}-issues`}>
        <li id={`${computedId}-dates-2012`} className="selected">
          <h1>2012</h1>
          <p>Président de la Commission de la CEDEAO, Abuja, Nigéria.</p>
        </li>
        <li id={`${computedId}-dates-2001`}>
          <h1>2001</h1>
          <p>Ambassadeur Extraordinaire et Plénipotentiaire du Burkina Faso auprès du Royaume de Belgique, du Royaume des Pays-Bas, du Royaume-Uni de Grande-Bretagne et d’Irlande du Nord, du Grand-Duché de Luxembourg, de l’Irlande et Représentant Permanent du Burkina Faso auprès de l'Union Européenne (UE), de l’Organisation pour l’Interdiction des Armes Chimiques (OIAC) et de l’Organisation Mondiale des Douanes (OMD).</p>
        </li>
        <li id={`${computedId}-dates-1996`}>
          <h1>1996</h1>
          <p>Premier Ministre, Chef du Gouvernement du Burkina Faso.</p>
          <p>En plus, Ministre de l'Économie et des Finances du Burkina Faso.</p>
        </li>
        <li id={`${computedId}-dates-1993`}>
          <h1>1993</h1>
          <p>Vice-Gouverneur de la Banque Centrale des Etats de l'Afrique de l'Ouest (BCEAO) à DAKAR, Sénégal.</p>
        </li>
        <li id={`${computedId}-dates-1985`}>
          <h1>1985</h1>
          <p>Secrétaire Exécutif Adjoint de la CEDEAO, chargé des Affaires Économiques (Lagos, Nigeria).</p>
        </li>
        <li id={`${computedId}-dates-1983`}>
          <h1>1983</h1>
          <p>Conseiller Financier du Secrétaire Général de la Communauté Économique de l’Afrique de l’Ouest (CEAO), Ouagadougou.</p>
        </li>
        <li id={`${computedId}-dates-1980`}>
          <h1>1980</h1>
          <p>Cadre à la Division Financière du FOSIDEC (CEAO, Ouagadougou).</p>
        </li>
        <li id={`${computedId}-dates-1977`}>
          <h1>1977</h1>
          <p>Conseiller des Affaires Economiques au Ministère du Commerce et du Développement Industriel (Ouagadougou, Burkina Faso).</p>
        </li>
      </ul>

      {/* VERTICAL COMMANDS */}
      {orientation == 'vertical' && (
        <div>
          <div id={`grad-${computedId}-prev`}>
            <a href="#" id={`${computedId}-prev`}>
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-up fa-w-10 fa-5x"><g className="fa-group"><path fill="currentColor" d="M63.52 351.84a23.94 23.94 0 0 1-33.85 0L7.05 329.33l-.18-.18a23.77 23.77 0 0 1 .18-33.61l96.42-96.15L160 255.86z" className="fa-secondary" fillOpacity="50%"></path><path fill="currentColor" d="M313.13 295.81l-.18-.17L177 160.11a24 24 0 0 0-34-.11l-39.51 39.39L256.33 352a23.94 23.94 0 0 0 33.85 0L313 329.43a23.78 23.78 0 0 0 .13-33.62z" className="fa-primary"></path></g></svg>
            </a>
          </div>
          <div id={`grad-${computedId}-next`}>
            <a href="#" id={`${computedId}-next`}>
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-down fa-w-10 fa-5x"><g className="fa-group"><path fill="currentColor" d="M160 256.14l-56.51 56.47-96.44-96.15a23.77 23.77 0 0 1-.18-33.61l.18-.18 22.59-22.51a23.94 23.94 0 0 1 33.85 0z" className="fa-secondary" fillOpacity="50%"></path><path fill="currentColor" d="M313 182.57L290.21 160a23.94 23.94 0 0 0-33.85 0L103.47 312.61 143 352l.06.06a24 24 0 0 0 33.93-.16L313 216.36l.18-.17a23.78 23.78 0 0 0-.18-33.62z" className="fa-primary"></path></g></svg>
            </a>
          </div>
        </div>
      )}

      {/* HORIZONTAL COMMANDS */}
      {orientation == 'horizontal' && (
      <div>
        <div id={`grad-${computedId}-prev`}>
          <a href="#" id={`${computedId}-prev`}>
            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="svg-inline--fa fa-angle-left fa-w-8 fa-5x"><g className="fa-group"><path fill="currentColor" d="M223.83 352.44a24 24 0 0 1 0 33.86L201.32 409l-.18.18a23.76 23.76 0 0 1-33.6-.18l-96.15-96.5 56.47-56.5z" className="fa-secondary" fillOpacity="50%"></path><path fill="currentColor" d="M167.81 102.87l-.17.18L32.11 239a24 24 0 0 0-.17 33.93l.06.07 39.39 39.51L224 159.66a23.92 23.92 0 0 0 0-33.84l-22.54-22.74a23.77 23.77 0 0 0-33.62-.23z" className="fa-primary"></path></g></svg>
          </a>
        </div>
        <div id={`grad-${computedId}-next`}>
          <a href="#" id={`${computedId}-next`}>
            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="svg-inline--fa fa-angle-right fa-w-8 fa-5x"><g className="fa-group"><path fill="currentColor" d="M128.14 256l56.47 56.49L88.46 409a23.76 23.76 0 0 1-33.6.18l-.18-.18-22.51-22.68a23.92 23.92 0 0 1 0-33.84z" className="fa-secondary" fillOpacity="50%"></path><path fill="currentColor" d="M54.58 103.07L32 125.81a23.92 23.92 0 0 0 0 33.84L184.61 312.5 224 273l.06-.06a24 24 0 0 0-.16-33.94L88.37 103l-.17-.18a23.78 23.78 0 0 0-33.62.22z" className="fa-primary"></path></g></svg>
          </a>
        </div>
      </div>)}
    </div>
  );
};

Timelinr.propTypes = propTypes;
Timelinr.defaultProps = defaultProps;

export default Timelinr;