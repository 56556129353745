import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import ReactGA from 'react-ga';
import { withAuth } from '@okta/okta-react';
import cx from 'classnames';

import ContactForm from '../ContactForm';
import { useAuth } from '../../auth';

import '../../App.css';

const propTypes = {
  contentComponent: PropTypes.object.isRequired,
  displayMainHeader: PropTypes.bool,
  containerClassName: PropTypes.string,
}

const defaultProps = {
  displayMainHeader: true,
  containerClassName: '',
}

const MainFrame = (props) => {
  const { contentComponent, displayMainHeader, containerClassName } = props;
  const authenticated = true;
  /*
  const [authenticated, user] = useAuth(auth);

  if (authenticated === false) {
    auth.login();
  }*/

  const measurementId = process.env.REACT_APP_GA_MID;
  ReactGA.initialize(measurementId);
  ReactGA.pageview(window.location.pathname + window.location.search);
  /*ReactGA.set({
    userId: user,
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  });*/

  useEffect(() => {

  });
  
  return authenticated && (
    <div id="mainFrame"className={cx('App', { [containerClassName]: containerClassName != ''})}>
      <nav className="navbar navbar-b navbar-trans navbar-expand-lg fixed-top" id="mainNav">
        <div className='container'>
          <div className="navbar-brand-left-container">
            <a className="navbar-brand js-scroll" href="/">KDO</a>
            <div className="socials">
              <ul>
                <li><a className="navbar-brand" target="_blank" href="https://www.facebook.com/KDO2020PRESI"><span className="ico-circle"><i className="ion-social-facebook"></i></span></a></li>
                <li><a className="navbar-brand" target="_blank" href="https://twitter.com/Kdo2020Officiel"><span className="ico-circle"><i className="ion-social-twitter"></i></span></a></li>
                <li><a className="navbar-brand" target="_blank" href="https://www.instagram.com/kdo2020"><span className="ico-circle"><i className="ion-social-instagram"></i></span></a></li>
                <li><a className="navbar-brand" target="_blank" href="https://www.youtube.com/channel/UCrp_5GsfTZoNCBbUyjkvDlQ/videos"><span className="ico-circle"><i className="ion-social-youtube"></i></span></a></li>
              </ul>
            </div>
          </div>
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/#news">Nouvelles</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#about">À propos</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#vision">Vision</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#realisations">Réalisations</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#social">Social</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#donations">Faire un don</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      <div id="home" className={cx("intro route bg-image", {hidden_intro: !displayMainHeader})} style={{ backgroundImage: "url(assets/img/intro-bg.jpg)", backgroundPosition: "75%" }}>
        <div className="overlay-itro"></div>
        <div className="intro-content display-table">
          <div className="table-cell">
            <div id="typed-words" className="container">
              <h1 className="intro-title mb-4">Agir ensemble pour</h1>
              <p className="intro-subtitle"><span className="text-slider-items">le Burkina Faso,l'avenir,les fils et filles du pays,la paix sociale</span><strong className="text-slider"></strong></p>
            </div>
          </div>
        </div>
      </div>

      <main id="main">
        <div className={cx('mainframe-maincontent')}>
          {contentComponent}
        </div>
        <section className="paralax-mf footer-paralax bg-image sect-mt4 route" style={{ backgroundImage: "url(assets/img/bg-savane.jpg)" }}>
          <div className="overlay-mf"></div>
          <div className="container send-message-container">
            <div className="row">
              <div className="col-sm-12">
                <div className="contact-mf">
                  <div id="contact" className="box-shadow-full">
                    <div className="row">
                      <div className="col-md-12 contact-form-outer-container">
                        <div className="title-box-2">
                          <h5 className="title-left">
                            Envoyez un message
                          </h5>
                        </div>
                        <div>
                          <ContactForm className="contact-form" />
                        </div>
                        <div id="contact-form-confirmation-veil"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box">
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
      {/* <a href="#" className="switch-into">1</a> */}
      <div id="preloader"></div>
    </div>
  );
}

MainFrame.propTypes = propTypes;
MainFrame.defaultProps = defaultProps;

export default MainFrame;