import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { withAuth } from '@okta/okta-react';
import { pdfjs } from 'react-pdf';
import { useAuth } from './auth';

import ContactForm from './components/ContactForm';
import Timelinr from './components/Timelinr';
import VideoModal from './components/VideoModal';

import './App.css';
import ScrollingModal from './components/ScrollingModal';

import PromoModal from './components/PromoModal';

const App = () => {

  const authenticated = true;
  /*
  const [authenticated, user] = useAuth(auth);

  if (authenticated === false) {
    auth.login();
  }*/

  const measurementId = process.env.REACT_APP_GA_MID;
  ReactGA.initialize(measurementId);
  ReactGA.pageview(window.location.pathname + window.location.search);
  /*ReactGA.set({
    userId: user,
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  });*/

  useEffect(() => {

  });
  
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  

  const getProgramContent = () => {
    return (
      <div className="promo-pogramme">
        <h1>Présidentielle 2020</h1>
        <div>
          <div className="promo-programme-text">
            <a target="_blank" href="assets/pdf/Programme Présidentiel de Kadré Désiré OUEDRAOGO - Aperçu.pdf">
              Consultez le programme de Kadré Désiré OUEDRAOGO
            </a> 
          </div>
          <div className="promo-programme-image">
            <a target="_blank" href="assets/pdf/PROGRAMME de KDO 28 OCTOBRE 2020.pdf">
              <img src="assets/pdf/programme_kadre_desire_ouedraogo_apercu.png" />
            </a> 
          </div>
          <div className="promo-programme-fineprint">
            <a target="_blank" href="assets/pdf/PROGRAMME de KDO 28 OCTOBRE 2020.pdf">
              Voir l'intégralité
            </a> 
          </div>
        </div>
      </div>
    );
  };

  const get2021Whishes = () => {
    return (
      <div className="promo-pogramme c-textual">
        <h1>VŒUX de nouvel an 2021</h1>
        <div>
          <div className="promo-programme-text-light">
            <p>
              A l’orée de la nouvelle année 2021, j’ai le grand plaisir et l’honneur d’adresser à tous mes compatriotes et aux amis du Burkina Faso, mes vœux les plus chaleureux de bonheur et de prospérité.
            </p>
            <p>
              L’année 2020 a été une année de grands défis pour notre pays. A la situation sécuritaire délétère, est venu s’ajouter le fléau de la pandémie de COVID-19 qui continue d’endeuiller de nombreuses familles.
            </p>
            <p>
              J’adresse à tous ceux et celles qui ont perdu un être cher durant l’année écoulée, mes sincères condoléances et ma totale compassion.
            </p>
            <p>
              Les élections couplées de novembre 2020 étaient une échéance cruciale pour notre peuple appelé à choisir ses dirigeants pour les cinq prochaines années. Grâce à la clairvoyance et à la sagesse de tous, notre pays a pu éviter une crise postélectorale qui pouvait naitre de ce scrutin émaillé d’irrégularités et de dysfonctionnements auxquels la CENI ne nous a pas habitués les 20 dernières années. Je souhaite que le sacrifice et le renoncement des uns et des autres servent à apaiser une situation de tension sociale et de conflits divers non résolus.
            </p>
            <p>
              Notre pays a besoin d’une réconciliation vraie et de l’engagement de tous à emprunter de nouveaux chemins de droiture et de probité, faute de quoi il sera difficile de corriger les dérives constatées dans notre processus électoral et dans notre société en général. Il faut un véritable changement de mentalité et un retour aux valeurs cardinales que nous ont légués nos ancêtres.
            </p>
            <p>
              J’invite nos concitoyens à la vigilance pour conforter notre processus démocratique et notre vivre ensemble.
            </p>
            <p>
              Bonne et heureuse année 2021 à toutes et à tous !
            </p>
            <br/>
            <p>Kadré Désiré Ouédraogo</p>
            <p>Grand-Croix de l’Ordre National</p>
          </div>
        </div>
      </div>
    );
  };
  
  const getDenisOuedraogoCondolences = () => {
    return (
      <div className="promo-pogramme c-textual">
        <h1>Repose en paix</h1>
        <div>
          <div className="promo-programme-text-light">
            <p>
              <img src="assets\img\nouvelles_denis_ouedraogo.jpg" alt="" className="img-fluid article-img" />
              Ce jour, j'ai accompagné à sa dernière demeure Denis Ouédraogo décédé le 2 juin 2021. Denis fut mon Directeur de cabinet lorsque j'étais Premier Ministre, puis Président de la Commission de la CEDEAO. C'est une grande tristesse pour moi car Denis était l'incarnation de la gentillesse, de la disponibilité et de la charité.  Ouvert à tous, grands comme petits, il était toujours présent pour rendre service.  Toujours jovial, il ne voyait dans son vis-à-vis que ce qui était bon en lui. Cette tolérance lui permettait d'être l'ami de tous.
            </p>
            <p>
              Pour sa grande famille dont il était l'aîné de 10 frères et sœurs, il a toujours été un frère prévenant et secourable. Il a servi le Burkina Faso avec compétence et droiture durant sa longue carrière de diplomate. C'est d'ailleurs au cours de cette carrière que nos chemins se sont croisés pour la première fois voilà plus de trente ans à Lagos au Nigeria. Depuis, il a toujours été à mes côtés aux jours de joie comme aux jours sombres. Je lui exprime ma reconnaissance pour cette amitié. 
            </p>
            <p>
              À sa famille éplorée, j’adresse mes condoléances les plus attristées. Plus particulièrement à son épouse Bernadette, à ses enfants Gislain, Carine et Godwill, j'exprime ma compassion et ma solidarité dans ce moment difficile. Que Dieu leur donne la force et le courage de supporter la douleur de la séparation. Cher Denis, repose en paix et que le Seigneur fasse briller sur toi la lumière de sa face.
            </p>
            <br/>
            <p>Kadré Désiré Ouédraogo</p>
          </div>
        </div>
      </div>
    );
  };

  const getSolhanAttackReactionMessage = () => {
    return (
      <div className="promo-pogramme c-textual">
        <div>
          <div className="promo-programme-text-light">
            <p>
              Face à l'effroyable drame de Solhan que je condamne avec la dernière énergie, j'adresse mes condoléances les plus émues aux familles éplorées et mon soutien aux forces de défense et de sécurité. Je présente à la nation Burkinabè toute entière mes condoléances. Ce drame nous interpelle sur la nécessité de retrouver le plus rapidement possible le chemin de la paix et de la sécurité. Nous pouvons le faire en changeant nos comportements et en servant avec sincérité et dévouement la cause de notre patrie. Que Dieu bénisse le Burkina Faso.
            </p>
            <br/>
            <p>Kadré Désiré Ouédraogo</p>
          </div>
        </div>
      </div>
    );
  };

  return authenticated && (
    <div className="App">
      
      <div id="promotions">
        {/* <a id="promotions-current" data-type="inline" href="assets/img/promotions/congres_investiture_2020.jpg"></a> */}
        <PromoModal
          triggerComponent={(<a id="promotions-current" data-type="inline" href="javascript:void(0);"></a>)}
          className="scrolling-modal"
          promo={get2021Whishes()}
        />
      </div>

      <nav className="navbar navbar-b navbar-trans navbar-expand-lg fixed-top" id="mainNav">
        <div className="container">
          <div className="navbar-brand-left-container">
            <a className="navbar-brand js-scroll" href="/">KDO</a>
            <div className="socials">
              <ul>
                <li><a className="navbar-brand" target="_blank" href="https://www.facebook.com/KDO2020PRESI"><span className="ico-circle"><i className="ion-social-facebook"></i></span></a></li>
                <li><a className="navbar-brand" target="_blank" href="https://twitter.com/Kdo2020Officiel"><span className="ico-circle"><i className="ion-social-twitter"></i></span></a></li>
                <li><a className="navbar-brand" target="_blank" href="https://www.instagram.com/kdo2020"><span className="ico-circle"><i className="ion-social-instagram"></i></span></a></li>
                <li><a className="navbar-brand" target="_blank" href="https://www.youtube.com/channel/UCrp_5GsfTZoNCBbUyjkvDlQ/videos"><span className="ico-circle"><i className="ion-social-youtube"></i></span></a></li>
              </ul>
            </div>
          </div>
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="navbar-collapse collapse justify-content-end" id="navbarDefault">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/nouvelles">Nouvelles</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#about">À propos</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#vision">Vision</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#realisations">Réalisations</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#social">Social</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#donations">Faire un don</a>
              </li>
              <li className="nav-item">
                <a className="nav-link js-scroll" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>


      <div id="home" className="intro route bg-image" style={{ backgroundImage: "url(assets/img/intro-bg.jpg)", backgroundPosition: "75%" }}>
        <div className="overlay-itro"></div>
        <div className="intro-content display-table">
          <div className="table-cell">
            <div id="typed-words" className="container">
              <h1 className="intro-title mb-4">Agir ensemble pour</h1>
              <p className="intro-subtitle"><span className="text-slider-items">le Burkina Faso,l'avenir,les fils et filles du pays,la paix sociale</span><strong className="text-slider"></strong></p>
            </div>
          </div>
        </div>
      </div>

      <main id="main">
        
      <div id="promotions">
        {process.env.REACT_APP_LIVE_EVENT_URL != null && process.env.REACT_APP_LIVE_EVENT_URL.trim() != '' && (<ScrollingModal 
          triggerComponent={(<a id="promotions-current" href="javascript:void(0);"></a>)}
          modalContent={'  '} 
        />)}
      </div>

      <section id="news" className="blog-mf sect-pt4 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Nouvelles
                  </h3>
                  <p className="subtitle-a">
                    Suivez l'actualité sur KDO
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            
            {/*--- FIRST ROW ---*/}
            <div className="row">
            
            <div className="col-md-4">
              <div className="card card-blog">
                <div className="card-img">
                  <PromoModal
                      triggerComponent={
                        (<a href="javascript:void(0);"><img src="assets\img\nouvelles_attaques_solhan.png" alt="" className="img-fluid" /></a>)
                      }
                      className="scrolling-modal"
                      promo={getSolhanAttackReactionMessage()}
                  />
                </div>
                <div className="card-body">
                  <div className="card-category-box">
                    <div className="card-category">
                      <h6 className="category">Attaques de Solhan</h6>
                    </div>
                  </div>
                  
                  <PromoModal 
                    triggerComponent={
                      (<h3 className="card-title"><a href="javascript:void(0);">Je présente à la nation Burkinabè toute entière mes condoléances</a></h3>)
                    }
                    className="scrolling-modal"
                    promo={getSolhanAttackReactionMessage()}
                  />
                  <p className="card-description">
                    Ce drame nous interpelle sur la nécessité de retrouver le plus rapidement possible le chemin de la paix et de la sécurité.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="post-author">
                    <PromoModal 
                      triggerComponent={
                        (<a href="javascript:void(0);">
                          <span className="author">
                            Voir l'intégralité
                          </span>
                      </a>)
                      }
                      className="scrolling-modal"
                      promo={getSolhanAttackReactionMessage()}
                    />
                  </div>
                  <div className="post-date">
                    <span className="ion-ios-clock-outline"></span> 07 Juin 2021
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card card-blog">
                <div className="card-img">
                  <PromoModal
                      triggerComponent={
                        (<a href="javascript:void(0);"><img src="assets\img\nouvelles_denis_ouedraogo.jpg" alt="" className="img-fluid" /></a>)
                      }
                      className="scrolling-modal"
                      promo={getDenisOuedraogoCondolences()}
                  />
                </div>
                <div className="card-body">
                  <div className="card-category-box">
                    <div className="card-category">
                      <h6 className="category">Denis Ouedraogo</h6>
                    </div>
                  </div>
                  
                  <PromoModal 
                    triggerComponent={
                      (<h3 className="card-title"><a href="javascript:void(0);">Il a servi le Burkina Faso avec compétence et droiture.</a></h3>)
                    }
                    className="scrolling-modal"
                    promo={getDenisOuedraogoCondolences()}
                  />
                  <p className="card-description">
                    Cher Denis, repose en paix et que le Seigneur fasse briller sur toi la lumière de sa face.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="post-author">
                    <PromoModal 
                      triggerComponent={
                        (<a href="javascript:void(0);">
                          <span className="author">
                            Voir l'intégralité
                          </span>
                      </a>)
                      }
                      className="scrolling-modal"
                      promo={getDenisOuedraogoCondolences()}
                    />
                  </div>
                  <div className="post-date">
                    <span className="ion-ios-clock-outline"></span> 07 Juin 2021
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card card-blog">
                <div className="card-img">
                  <PromoModal
                      triggerComponent={
                        (<a href="javascript:void(0);"><img src="assets\img\nouvelles_voeux_2021.png" alt="" className="img-fluid" /></a>)
                      }
                      className="scrolling-modal"
                      promo={get2021Whishes()}
                  />
                </div>
                <div className="card-body">
                  <div className="card-category-box">
                    <div className="card-category">
                      <h6 className="category">VŒUX de nouvel an 2021</h6>
                    </div>
                  </div>
                  
                  <PromoModal 
                    triggerComponent={
                      (<h3 className="card-title"><a href="javascript:void(0);">Voeux pour l'année 2021</a></h3>)
                    }
                    className="scrolling-modal"
                    promo={get2021Whishes()}
                  />
                  <p className="card-description">
                    Kadré Désiré OUEDRAOGO adresse à ses compatriotes et aux amis du Burkina Faso ses voeux les plus sincères.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="post-author">
                    <PromoModal 
                      triggerComponent={
                        (<a href="javascript:void(0);">
                          <span className="author">
                            Voir l'intégralité
                          </span>
                      </a>)
                      }
                      className="scrolling-modal"
                      promo={get2021Whishes()}
                    />
                  </div>
                  <div className="post-date">
                    <span className="ion-ios-clock-outline"></span> 31 Décembre 2020
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                    <PromoModal
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_programme_kdo.jpg" alt="" className="img-fluid" /></a>)
                        }
                        promo={getProgramContent()}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Programme de KDO</h6>
                      </div>
                    </div>
                    <PromoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Consultez le programme de Kadré Désiré OUEDRAOGO</a></h3>)
                      }
                      promo={getProgramContent()}
                    />
                    <p className="card-description">
                      Kadré Désiré OUEDRAOGO expose son programme pour un Burkina Faso uni et prospère.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <a target="_blank" href="assets/pdf/PROGRAMME de KDO 28 OCTOBRE 2020.pdf">
                        <span className="author">
                          Voir l'intégralité
                        </span>
                      </a>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 3 novembre 2020
                    </div>
                  </div>
                </div>
              </div>

             <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_lefaso_tv.jpg" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=TI52UE0iDho`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Le Faso TV</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">"Gouverner par l'exemple" : Le Faso TV reçoit Kadré Désiré OUEDRAOGO</a></h3>)
                      }
                      videoUrl={`https://www.youtube.com/watch?v=TI52UE0iDho`}
                    />
                    <p className="card-description">
                      Écoutez KDO répondre aux questions de Cryspin LAOUNDIKI et de Oumar OUEDRAOGO sur sa vision pour le Burkina.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=TI52UE0iDho`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 13 novembre 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_agir_ensemle_unis.png" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/2807741586218270/?__so__=channel_tab&__rv__=all_videos_card`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Message de KDO</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Un autre avenir pour le Burkina Faso</a></h3>)
                      }
                      videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/2807741586218270/?__so__=channel_tab&__rv__=all_videos_card`}
                    />
                    <p className="card-description">
                      Kadré Désiré OUEDRAOGO se présente à vous et appel à l'unité.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.facebook.com/KDO2020PRESI/videos/2807741586218270/?__so__=channel_tab&__rv__=all_videos_card`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 31 octobre 2020
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);"><img src="assets\img\nouvelles_grand_deballage.jpg" alt="" className="img-fluid" />
                          <div className="news-video-vail"></div>
                          <i className="fa fa-youtube-play fa-5x"></i></a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=PqT6d8_FzMA`}
                      />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">Le Grand Déballage</h6>
                      </div>
                    </div>
                    <VideoModal 
                      triggerComponent={
                        (<h3 className="card-title"><a href="javascript:void(0);">Le Grand Déballage avec Kadré Désiré Ouédraogo</a></h3>)
                      }
                      videoUrl={`https://www.youtube.com/watch?v=PqT6d8_FzMA`}
                    />
                    <p className="card-description">
                      Écoutez KDO répondre aux questions du Grand Déballage et présenter sa vision pour le Burkina Faso.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <VideoModal 
                        triggerComponent={
                          (<span className="author"><a href="javascript:void(0);">Voir la vidéo...</a></span>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=PqT6d8_FzMA`}
                      />
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 15 décembre 2019
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card card-blog">
                  <div className="card-img">
                    <VideoModal 
                          triggerComponent={
                            (<a href="javascript:void(0);"><img src="assets/img/nouvelles_rtb_sur_la_breche.png" alt="" className="img-fluid" />
                            <div className="news-video-vail"></div>
                            <i className="fa fa-youtube-play fa-5x"></i></a>)
                          }
                          videoUrl={`https://www.youtube.com/watch?v=SLZi2sASmBg`}
                        />
                  </div>
                  <div className="card-body">
                    <div className="card-category-box">
                      <div className="card-category">
                        <h6 className="category">RTB</h6>
                      </div>
                    </div>
                    <h3 className="card-title">
                      <VideoModal 
                        triggerComponent={
                          (<a href="javascript:void(0);">RTB - Sur la Brèche avec Kadré Désiré OUEDRAOGO</a>)
                        }
                        videoUrl={`https://www.youtube.com/watch?v=SLZi2sASmBg`}
                      />
                    </h3>
                    <p className="card-description">
                      KDO est l'invité de l'émission "Sur la Brèche" de la Radiodiffusion de Télévision du Burkina (RTB). KDO répond à plusieurs questoins portant sur son parcours et sa vision.
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="post-author">
                      <span className="author">
                        <VideoModal 
                          triggerComponent={
                            (<a href="javascript:void(0);">Voir la vidéo...</a>)
                          }
                          videoUrl={`https://www.youtube.com/watch?v=SLZi2sASmBg`}
                        />
                      </span>
                    </div>
                    <div className="post-date">
                      <span className="ion-ios-clock-outline"></span> 6 juin 2020
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {/*--- SECOND ROW ---*/}
            <div className="row">
              <div className="col-md-12 see-all-news">
                <div>
                  <a href="/nouvelles">Voir toutes les nouvelles...</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about-mf sect-pt4 route">
          <div className="container">
          <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    À propos
                  </h3>
                  <p className="subtitle-a">
                    Découvrez qui est KDO
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="box-shadow-full profil-candidat-box">
                  <div className="row">
                    <div className="col-md-6 about-mf-left">
                      <div className="row">
                        <div className="about-img">
                          <img src="assets/img/a_propos_kadre_desire_ouedraogo.png" className="img-fluid rounded b-shadow-a kdo-portrait" alt="" />
                        </div>
                        <Timelinr
                          idName="skill-mf-timeline"
                          orientation="vertical"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 about-mf-right">
                      <div className="about-me pt-4 pt-md-0">
                        <div className="title-box-2">
                          <h5 className="title-left">
                            Kadré Désiré OUEDRAOGO
                          </h5>
                        </div>
                        <p className="lead">
                          Il est diplômé de la prestigieuse École des Hautes Études Commerciales (HEC Paris) et titulaire d’un diplôme d’Études Économiques Générales et d’une Licence Ès Sciences Économiques obtenus à l’Université de Paris-I Panthéon-Sorbonne.
                        </p>
                        <p className="lead">
                          Sa carrière professionnelle et son parcours politique l’ont amené à exercer des fonctions de premier plan à l’échelon national, comme au niveau international. Ainsi, il a été successivement Secrétaire Exécutif Adjoint de la Communauté Économique des États de l’Afrique de l’Ouest (CEDEAO), Vice- Gouverneur de la Banque Centrale des États de l’Afrique de l’Ouest (BCEAO), Premier Ministre du Burkina de 1996 à 2000, Ambassadeur du Burkina Faso auprès de la Belgique et de la Commission de l’Union Européenne, Président de la Commission de la CEDEAO de 2012 à 2016.
                        </p>
                        <p className="lead">
                          En sa qualité de premier ministre du Burkina Faso, il a oeuvré activement au bien-être des populations, comme en témoignent ses multiples réalisations et la reconnaissance de la nation. Des réformes engagées et accomplies, aux nombreux acquis économiques et sociaux, il aura imprégné sa marque dans la mémoire collective des Burkinabè pour qui il n’a cesse d’admiration.
                        </p>
                        <p className="lead">
                          Les services éminents qu’il a rendus à la CEDEAO lui ont valu de recevoir les plus hautes distinctions honorifiques dans plusieurs des États membres : Grand-Croix de l’Ordre National du Burkina Faso, Grand Officier de l’Ordre National du Bénin, Commandeur de l’Ordre National du Sénégal, Commandeur de l’Ordre National de Côte-d’Ivoire, Commandeur de l’Ordre National du Togo.
                        </p>
                        <p className="lead">
                          Fidèle à ses valeurs de disponibilité et d’écoute, il a toujours répondu favorablement aux multiples sollicitations telles que chef de la mission d’observation électorale de la CEDEAO pour l’élection présidentielle malienne, en juillet 2018. En Octobre 2018 il a également conduit la mission de l’Organisation Internationale de la Francophonie (OIF) pour l’élection présidentielle au Cameroun en qualité d’Envoyé Spécial.
                        </p>
                        <p className="rencontrez-kdo-link">
                          <a className="nav-link js-scroll" href="#vision">Découvrez la vision de KDO</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <Timelinr
                      idName="skill-mf-timeline"
                      orientation="horizontal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="vision" className="services-mf pt-5 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Vision de KDO
                  </h3>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>

            <div className="row vision-preview">
              <div className="col-md-3">
                <div>
                  <div className="work-box">
                    <div className="work-img">
                      <img src="assets/img/vision_1.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="preview">
                  <blockquote>
                    <p>
                      Replaçons notre jeunesse au cœur du moteur de la transformation sociale, instaurons le dialogue ouvert et sincère pour des solutions de compromis acceptables. Oui, j’appelle à l’action tous les Burkinabè de bonne volonté : mobilisons nous au service de notre pays; osons l’action pour la cause commune ; donnons le meilleur pour un avenir radieux des générations actuelles et futures ; construisons un Burkina nouveau; osons un nouveau départ!
                    </p>
                    <p>Je demeure à votre écoute et mon engagement avec vous est total.</p>
                  </blockquote>
                  <div className="w-more">
                    <span className="w-ctegory">Discours à Bobo Dioulasso</span> le <span className="w-date">16 février 2019</span>
                  </div>
                  <div className="see-more w-like">
                    <a href="#" className="see-more-link display-speech"> Lire l'intégralité </a><a href="#" className="see-more-icon display-speech"><span className="ion-ios-plus-outline"></span></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row vision-detail">
              {/* Constat */}
              <h2>À la croisée des chemins</h2>
              <div className="row" style={{ backgroundColor: `#f1f1f1f1`, padding: "1.5rem 3rem", marginBottom: `10px` }}>
                <div className="row" style={{ marginBottom: `10px` }}>
                  <p>Faisons le constat de la situation politique, économique et sociale précaire que traverse notre chère patrie le Burkina Faso.</p>
                </div>
                <div className="row" style={{ marginBottom: `10px` }}>
                  <div className="col-md-6">
                    <p>Sur le plan politique, force est d’observer une gouvernance générale défaillante, qui se traduit par une politique de courte échelle, une défiance de l’autorité de l’Etat, l’abandon de l’amour du travail et des idéaux de justice et d’intégrité, les grèves à la chaîne, les revendications souvent maximalistes, l’insécurité.</p>
                    <p>Sur le plan économique, nous assistons à une course effrénée au profit, dans laquelle les plus faibles sont piétinés par les plus forts; une course occasionnée par l’absence ou l’inefficacité d’une saine gestion des ressources naturelles, financières et du patrimoine national, l’insouciance, l’incompétence, voir le mépris de la chose publique.</p>
                    <p>A cela ajoutons le manque d’infrastructures scolaires, de santé, de routes, d’électricité et d’eau, l’on comprend la difficulté d’assurer le progrès économique dans un environnement si peu propice.</p>
                  </div>
                  <div className="col-md-6">
                    <p>Nous voyons ainsi se refléter sur le plan social, de nombreuses fractures caractérisées par l’incivisme grandissant, la corruption, le chacun pour soi, l’injustice et les inégalités sociales, l’exclusion et l’insensibilité à la détresse d’autrui. Le tissu social s’effrite au point que l’on peut voire la jeunesse adopter une posture contraire à notre culture et à nos traditions. A cette effervescence sociale se greffe, depuis quelques années, le terrorisme qui fait à l’aveugle toutes ces victimes pour lesquelles je m’incline, en leurs mémoires et celles de leurs familles éplorées. Ce terrorisme mine ainsi la sécurité des paisibles habitants, menace l’intégrité territoriale et compromet gravement le développement économique.</p>
                    <p>Nos responsabilités collectives et individuelles, à travers nos paroles, nos comportements, nos silences complices, nous interpellent à sortir au plus vite notre pays, le Burkina, de cette situation inacceptable qui tient en otage l’héritage de nos aïeux et nous empêche de léguer aux générations futures cette patrie prospère de paix , de bravoure, d’honneur et d’entente.</p>
                  </div>
                </div>
              </div>

              {/* Pratico-pratique */}
              <h2>Gouvernance axée sur nos valeurs</h2>
              <div className="row" style={{ marginBottom: `10px` }}>
                <p>Sans minimiser ni ignorer les efforts déployés à ce jour par le gouvernement et l’ensemble des acteurs politiques et sociaux du moment, force est de reconnaître que notre cher pays le Burkina Faso est à la croisée des chemins. Aux nombreuses voix de ces hommes et femmes qui se lèvent avec détermination pour l’action, pour un changement radical, c’est avec honneur et gratitude que je me fais votre candidat pour mettre tout en oeuvre afin que le Burkina Faso retrouve le progrès et la paix, dans une unité nationale retrouvée et renforcée.
                  <br />
                  Nous le ferons ensemble, en fédérant nos efforts, et convaincus :
                </p>
              </div>
              <div className="row" style={{ marginBottom: `10px` }}>
                <div className="col-md-4">
                  <div className="work-img first">
                    <img src="assets/img/vision_1.jpg" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-4">
                  <ul className="vision-topics-first-coloumn gouv">
                    <li><span className="vision-highlight">1</span> - Que toute action politique et sociale doit s’inscrire dans le respect de la constitution et des principes démocratiques généralement reconnus ; </li>
                    <li><span className="vision-highlight">2</span> - Qu’une nation ne peut se bâtir dans l’injustice, la haine, la tricherie, l’exclusion ou l’indifférence des uns vis-à-vis des autres ; </li>
                    <li><span className="vision-highlight">3</span> - Qu’une nation ne peut se construire sans un travail appliqué et efficient de ses fils et filles, fruit d’une bonne formation et éducation de la jeunesse et d’une saine gestion des ressources humaines ; </li>
                    <li><span className="vision-highlight">4</span> - Qu’une nation ne peut s’épanouir sans une réelle liberté pour tous : liberté de pensée, de croyance, d’action et d’association, mais une liberté qui respecte l’intérêt commun et prend en compte le devoir de solidarité ; </li>
                    <li><span className="vision-highlight">5</span> - Que la liberté d’entreprendre et la propriété privée doivent être garanties, mais que l’Etat doit veiller à ce que le devoir de solidarité envers les plus démunis soit pris en compte dans la politique de redistribution des richesses nationales ; </li>
                    <li><span className="vision-highlight">6</span> - Que la sécurité de tous et de chacun doit être garantie par l’Etat qui doit jouer pleinement son rôle de garant de l’ordre et de la loi, et de l’intégrité territoriale du pays ; </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="vision-topics-second-coloumn gouv">
                    <li><span className="vision-highlight">7</span> - Qu’une saine gestion des ressources et du patrimoine national est la base fondamentale de l’action gouvernementale ; </li>
                    <li><span className="vision-highlight">8</span> - Qu’une répartition équitable des fruits du travail national est un devoir pressant de l’Etat ;</li>
                    <li><span className="vision-highlight">9</span> - Que l’autosuffisance alimentaire est la première garantie de la souveraineté et l’indépendance nationales ; </li>
                    <li><span className="vision-highlight">10</span> - Que l’accès de tous aux besoins sociaux de base (santé, éducation, logement) doit constamment guider l’action des gouvernants ; </li>
                    <li><span className="vision-highlight">11</span> - Que tous les Burkinabè doivent jouir des mêmes droits sans discrimination de quelque nature que ce soit ;</li>
                    <li><span className="vision-highlight">12</span> - Que des infrastructures de qualité sont la base essentielle et indispensable de toute économie prospère ; </li>
                    <li><span className="vision-highlight">13</span> - Qu’une nation forte ne peut se bâtir que sur des valeurs fortes et partagées, tirées de la diversité culturelle et de l’identité nationale.</li>
                  </ul>
                </div>
              </div>

              {/* Pratico-pratique */}
              <h2>Projet de société inclusif</h2>
              <div className="row" style={{ backgroundColor: `rgb(231 239 241)`, padding: "1.5rem 3rem", marginBottom: `15px` }}>
                <div className="row" style={{ marginBottom: `10px` }}>
                  <p>Sur la base de ces principes, une véritable action politique et sociale doit être engagée pour faire résolument face aux défis du moment en  s’appuyant sur les piliers suivants, qui sont le fondement même et la condition du succès :</p>
                </div>
                <div className="row" style={{ marginBottom: `10px` }}>
                  <div className="col-md-4">
                    <div className="work-img second">
                      <img src="assets/img/vision_2.jpg" alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <ul className="gouv-check">
                      <li>Favoriser la réconciliation nationale;</li>
                      <li>Assurer le respect des droits humains et des libertés;</li>
                      <li>Restaurer l’autorité de l’Etat ;</li>
                      <li>Assurer la défense de l’intégrité territoriale du pays, la sécurité de ses habitants et de leurs biens ;</li>
                      <li>Assurer une justice indépendante et accessible à tous ;</li>
                      <li>Engager la lutte contre la corruption et l’enrichissement illicite ;</li>
                      <li>Réorganiser la fonction publique et assurer l’efficacité du service public ;</li>
                      <li>Veiller à une gestion transparente des biens publics et une rigueur sans faille dans la gestion financière et comptable de l’Etat et ses démembrements.;</li>
                      <li>Donner les moyens nécessaires aux forces de défense et de sécurité pour garantir efficacement l’intégrité territoriale du pays ainsi que la protection des personnes et des biens;</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="gouv-check">
                      <li>Repenser l’éducation et la formation en orientant en priorité les moyens vers l’enseignement et la recherche ;</li>
                      <li>Revoir le système de santé pour garantir à tous l’accès à des soins de qualité ;</li>
                      <li>Promouvoir une agriculture performante dans un environnement durable ;</li>
                      <li>Veiller à l’édification d’infrastructures institutionnelles et physiques dans tous les domaines en insistant sur l’énergie, les transports et communication, afin de soutenir l’industrie et le commerce;</li>
                      <li>Favoriser l’essor de l’économie numérique et une meilleure connexion au monde par les nouvelles techniques d’information et de communication ;</li>
                      <li>Instaurer un environnement macro-économique propice à l’investissement et à l’épargne;</li>
                      <li>S’engager résolument dans les stratégies d’intégration régionales en exigeant l’application par tous des décisions en la matière.</li>
                    </ul>
                  </div>
                </div>
              </div>


              {/* Pratico-pratique */}
              <h2>Engagement total</h2>
              <div className="row" style={{ marginBottom: `35px` }}>
                <div className="col-md-4">
                  <div className="work-img third">
                    <img src="assets/img/vision_3.png" alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-8" style={{ marginBottom: `35px` }}>
                  <p>Mes chers compatriotes, frères et soeurs, la destinée de notre mère patrie est entre nos mains. Assumons nous, fils et filles du Faso, citoyens à part entière, rebâtissons notre patrie, transcendons nos différents, mettons-nous assidument au travail: personne ne le fera à notre place, ni mieux que nous. Replaçons notre jeunesse au cœur du moteur de la transformation sociale, instaurons le dialogue ouvert et sincère pour des solutions de compromis acceptables. Oui, j’appelle à l’action tous les Burkinabè de bonne volonté : mobilisons nous au service de notre pays; osons l’action pour la cause commune ; donnons le meilleur pour un avenir radieux des générations actuelles et futures ; construisons un Burkina nouveau; osons un nouveau départ!</p>
                  <p>Je demeure à votre écoute et mon engagement avec vous est total. Merci à tous.<br />Que Dieu bénisse notre pays, le Burkina Faso!</p>

                  <p className="kdo">— Kadré Désiré OUEDRAOGO.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="realisations" className="services-mf pt-5 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Réalisations
                  </h3>
                  <p className="subtitle-a">
                    Quelques illustrations des réalisations de KDO.
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    {/* <span className="ico-circle"><i className="ion-monitor"></i></span> */}
                    <img src="assets/img/realisations_tarif_exterieur_commun_cedeao.jpg" alt="" className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Le Tarif extérieur Commun de la CEDEAO</h2>
                    <p className="s-description text-center">
                      Le Tarif extérieur Commun de la CEDEAO est l'un des instruments d'harmonisation des politiques commerciales de la CEDEAO et de renforcement de son Marché Commun
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    {/* <span className="ico-circle"><i className="ion-code-working"></i></span> */}
                    <img src="assets/img/realisations_carte_identite_biometrique.jpg" alt="" className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Carte d'identité biométrique de la CEDEAO</h2>
                    <p className="s-description text-center">
                      La carte d'identité électronique biométrique de la CEDEAO sert de document d'identité du citoyen grâce à la reconnaissance du visage et des empreintes digitales, permettant la libre circulation dans les pays membres de la CEDEAO.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    {/* <span className="ico-circle"><i className="ion-camera"></i></span> */}
                    <img src="assets/img/realisations_partenariats_economiques.jpg" alt="" className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Partenariats Économiques</h2>
                    <p className="s-description text-center">
                      Contribution aux discussions sur les Accords de Partenariat Économique Union-Européenne - Afrique de l’ouest.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    {/* <span className="ico-circle"><i className="ion-android-phone-portrait"></i></span> */}
                    <img src="assets/img/realisations_lutte_contre_ebola.jpg" alt="" className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Ébola</h2>
                    <p className="s-description text-center">
                      Supervision de la gestion de la crise d'Ébola, en Afrique de l'Ouest.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    {/* <span className="ico-circle"><i className="ion-paintbrush"></i></span> */}
                    <img src="assets/img/realisations_observation_mali.jpg" alt="" className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Observation Électorale</h2>
                    <p className="s-description text-center">
                      Direction de la mission d’observation électorale à court terme de l’Organisation Régionale pour l’Élection Présidentielle du 29 juillet 2018 au Mali.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="service-ico">
                    {/* <span className="ico-circle"><i className="ion-stats-bars"></i></span> */}
                    <img src="assets/img/realisations_distinctions.jpg" alt="" className="img-fluid" />
                  </div>
                  <div className="service-content">
                    <h2 className="s-title">Distinctions honorifiques</h2>
                    <p className="s-description text-center">
                      Grand-Croix de l’Ordre National du Burkina Faso, Grand Officier de l’Ordre National du Bénin, Commandeur de l’Ordre National du Sénégal, Commandeur de l’Ordre National de Côte-d’Ivoire, Commandeur de l’Ordre National du Togo, Commandeur de l’Ordre du Mérite du Conseil International du Sport Militaire.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div className="section-counter paralax-mf bg-image" style={{ backgroundImage: "url(assets/img/bg-chuttes.jpg)" }}>
          <div className="overlay-mf"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-3 col-lg-3">
                <div className="counter-box counter-box pt-4 pt-md-0">
                  <div className="counter-ico">
                    <span className="ico-circle"><i className="ion-checkmark-round"></i></span>
                  </div>
                  <div className="counter-num">
                    <p className="counter">43</p>
                    <span className="counter-text">ANNÉES D'EXPÉRIENCE EN AFFAIRES PUBLIQUES, ÉCONOMIQUES ET SOCIALES</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-lg-3">
                <div className="counter-box pt-4 pt-md-0">
                  <div className="counter-ico">
                    <span className="ico-circle"><i className="ion-ios-calendar-outline"></i></span>
                  </div>
                  <div className="counter-num">
                    <p className="counter">5</p>
                    <span className="counter-text">ANNÉES COMME CHEF DU GOUVERNEMENT DU BURKINA FASO</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-lg-3">
                <div className="counter-box pt-4 pt-md-0">
                  <div className="counter-ico">
                    <span className="ico-circle"><i className="ion-ios-people"></i></span>
                  </div>
                  <div className="counter-num">
                    <p className="counter">5</p>
                    <span className="counter-text">RÉFORMES ET RÉALISATIONS MAJEURES AYANT IMPACTÉ LE BURKINA FASO</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-lg-3">
                <div className="counter-box pt-4 pt-md-0">
                  <div className="counter-ico">
                    <span className="ico-circle"><i className="ion-ribbon-a"></i></span>
                  </div>
                  <div className="counter-num">
                    <p className="counter">20</p>
                    <span className="counter-text">ANNÉES DE GESTION D'ÉVÈNEMENTS INTERNATIONAUX D'ENVERGURE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="social" className="portfolio-mf sect-pt4 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Implications politiques et sociales
                  </h3>
                  <p className="subtitle-a">
                    KDO en action
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="work-box">
                  <a href="assets/img/implications_politiques_sociales_1.jpg" data-gall="portfolioGallery" className="venobox">
                    <div className="work-img">
                      <img src="assets/img/implications_politiques_sociales_1.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="work-content">
                      <div className="row">
                        {/* <div className="col-sm-8">
                          <h2 className="w-title">KDO à Yamoussoukro</h2>
                          <div className="w-more">
                            <span className="w-ctegory">Yamoussoukro - Côte d'Ivoire</span> / <span className="w-date">21 mars 2016</span>
                          </div>
                        </div> */}
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="w-like social-more">
                            <span className="fa fa-search-plus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="work-box">
                  <a href="assets/img/implications_politiques_sociales_2.jpg" data-gall="portfolioGallery" className="venobox">
                    <div className="work-img">
                      <img src="assets/img/implications_politiques_sociales_2.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="work-content">
                      <div className="row">
                        {/* <div className="col-sm-8">
                          <h2 className="w-title">KDO à Ouagadougou</h2>
                          <div className="w-more">
                            <span className="w-ctegory">Ouagadougou - Burkina Faso</span> / <span className="w-date">22 janvier 2020</span>
                          </div>
                        </div> */}
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="w-like social-more">
                            <span className="fa fa-search-plus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="work-box">
                  <a href="assets/img/implications_politiques_sociales_3.jpg" data-gall="portfolioGallery" className="venobox">
                    <div className="work-img">
                      <img src="assets/img/implications_politiques_sociales_3.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="work-content">
                      <div className="row">
                        {/* <div className="col-sm-8">
                          <h2 className="w-title">KDO à Ouagadougou</h2>
                          <div className="w-more">
                            <span className="w-ctegory">Ouagadougou - Burkina Faso</span> / <span className="w-date">27 février 2019</span>
                          </div>
                        </div> */}
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="w-like social-more">
                            <span className="fa fa-search-plus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="work-box">
                  <a href="assets/img/implications_politiques_sociales_4.jpg" data-gall="portfolioGallery" className="venobox">
                    <div className="work-img">
                      <img src="assets/img/implications_politiques_sociales_4.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="work-content">
                      <div className="row">
                        {/* <div className="col-sm-8">
                          <h2 className="w-title">KDO à Bobo Dioulasso</h2>
                          <div className="w-more">
                            <span className="w-ctegory">Bobo Dioulasso - Burkina Faso</span> / <span className="w-date">18 février 2019</span>
                          </div>
                        </div> */}
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="w-like social-more">
                            <span className="fa fa-search-plus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="work-box">
                  <a href="assets/img/implications_politiques_sociales_5.jpg" data-gall="portfolioGallery" className="venobox">
                    <div className="work-img">
                      <img src="assets/img/implications_politiques_sociales_5.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="work-content">
                      <div className="row">
                        {/* <div className="col-sm-8">
                          <h2 className="w-title">KDO à Abuja</h2>
                          <div className="w-more">
                            <span className="w-ctegory">Abuja - Nigéria</span> / <span className="w-date">08 mars 2016</span>
                          </div>
                        </div> */}
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="w-like social-more">
                            <span className="fa fa-search-plus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="work-box">
                  <a href="assets/img/implications_politiques_sociales_6.jpg" data-gall="portfolioGallery" className="venobox">
                    <div className="work-img">
                      <img src="assets/img/implications_politiques_sociales_6.jpg" alt="" className="img-fluid" />
                    </div>
                    <div className="work-content">
                      <div className="row">
                        {/* <div className="col-sm-8">
                          <h2 className="w-title">KDO à Accra</h2>
                          <div className="w-more">
                            <span className="w-ctegory">Accra - Ghana</span> / <span className="w-date">3 juillet 2014</span>
                          </div>
                        </div> */}
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                          <div className="w-like social-more">
                            <span className="fa fa-search-plus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
{/* 
        <div className="testimonials paralax-mf bg-image" style={{ backgroundImage: "url(assets/img/bg-arbres.jpg)" }}>
          <div className="overlay-mf"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="testimonial-mf" className="owl-carousel owl-theme">
                  <div className="testimonial-box">
                    <div className="testimonials-attestant">
                      <img src="assets/img/temoignage-mamadou-djibo-baane-badikirane.jpg" alt="" className="rounded-circle b-shadow-a" />
                      <span className="author">Djibo Baanè-Badikiranè</span>
                    </div>
                    <div className="content-test">
                      <p className="description lead">
                        Nous avons besoin de changer le leadership national en 2020. Kadré Désiré Ouédraogo est le candidat qui saura construire une coalition politique transpartisane et transgénérationnelle pour imposer des élections libres, transparentes, inclusives, paisibles et crédibles.
                      </p>
                      <span className="comit"><i className="fa fa-quote-right"></i></span>
                    </div>
                  </div>
                  <div className="testimonial-box">
                    <div className="testimonials-attestant">
                      <img src="assets/img/temoignage-leonce-kone.jpg" alt="" className="rounded-circle b-shadow-a" />
                      <span className="author">Léonce Koné</span>
                    </div>
                    <div className="content-test">
                      <p className="description lead">
                        Il a la volonté et la capacité d’apporter des changements bénéfiques dans la gouvernance du pays, en réalisant la réconciliation nationale, en restaurant l’autorité de l’Etat, et en mettant en œuvre des politiques adaptées à la complexité, à l’urgence, à la gravité des défis actuels.
                      </p>
                      <span className="comit"><i className="fa fa-quote-right"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section id="donations" className="blog-mf sect-pt4 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">
                    Faire un don
                  </h3>
                  <p className="subtitle-a">
                    La vision de KDO m'inspire. Soutenons, Contribuons, Agissons ensemble
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row donations-options-container">
              <div className="col-md-6">
                <div className="donation_medium_visual">
                  <div className="donation-image">
                    <img src="assets/img/faire_un_don_orange_money.png" />
                  </div>
                  <div className="donation-explanation">
                    <p><b>OrangeMoney - Envoyer de l'argent</b></p>
                    <ul>
                      <li>
                        Étape 1 : Composez le <span className="orange-money-highlight">*144#</span>
                      </li>
                      <li>
                        Étape 2 : Choisir <span className="orange-money-highlight">2</span> et valider
                      </li>
                      <li>
                        Étape 3 : Choisir <span className="orange-money-highlight">1</span> et valider
                      </li>
                      <li>
                        Étape 4 : Saisir le numéro du bénéficiaire : <span className="orange-money-highlight">+226 75387644</span>
                      </li>
                      <li>
                        Étape 5 : Saisir le montant du transfert
                      </li>
                      <li>
                        Étape 6 : Saisir le code PIN (mot de passe)
                      </li>
                      <li>
                        Étape 7 : SMS de notification de la transaction à confirmer
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="donation_medium_visual">
                  <div className="donation-image mobicash-image-container">
                    <img src="assets/img/faire_un_don_mobicash.jpg" />
                  </div>
                  <div className="donation-explanation">
                    <p><b>MobiCash - Envoyer de l'argent</b></p>
                    <ul>
                      <li>
                        Étape 1 : Composez le <span className="mobi-cash-highlight">*555#</span>
                      </li>
                      <li>
                        Étape 2 : Choisir <span className="mobi-cash-highlight">2</span> et valider
                      </li>
                      <li>
                        Étape 3 : Choisir <span className="mobi-cash-highlight">1</span> et valider
                      </li>
                      <li>
                        Étape 4 : Saisir le numéro du bénéficiaire : <span className="mobi-cash-highlight">+226 70356190</span>
                      </li>
                      <li>
                        Étape 5 : Saisir le montant du transfert
                      </li>
                      <li>
                        Étape 6 : Saisir le code PIN (mot de passe)
                      </li>
                      <li>
                        Étape 7 : SMS de notification de la transaction à confirmer
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row donations-options-container">
              <div className="col-md-6">
                <div className="donation_medium_visual">
                  <div className="donation-image interac-image-container">
                    <img src="assets/img/faire_un_don_interac.jpg  " />
                  </div>
                  <div className="donation-explanation">
                    <p><b>Vous pouvez faire parvenir vos contributions à l'adresse suivante :</b></p>
                    <p><span className="interac-highlight">dons@kdo2020.com</span></p>
                    <p>Réponse : KDO2020</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row donations-options-container donation-assistance">
              <p>
                Pour assistance par téléphone, veuillez contacter le : +226 54546153<sup>*</sup> ou +226 58771877<sup>*</sup>.
              </p>
              <p>*<span className="nb-small">Des frais d'interurbains et d'itinérance peuvent s'appliquer.</span></p>
            </div>
          </div>
        </section>

        <section className="paralax-mf footer-paralax bg-image sect-mt4 route" style={{ backgroundImage: "url(assets/img/bg-savane.jpg)" }}>
          <div className="overlay-mf"></div>
          <div className="container send-message-container">
            <div className="row">
              <div className="col-sm-12">
                <div className="contact-mf">
                  <div id="contact" className="box-shadow-full">
                    <div className="row">
                      <div className="col-md-12 contact-form-outer-container">
                        <div className="title-box-2">
                          <h5 className="title-left">
                            Envoyez un message
                          </h5>
                        </div>
                        <div>
                          <ContactForm className="contact-form" />
                        </div>
                        <div id="contact-form-confirmation-veil"></div>
                      </div>
                      {/*<div className="col-md-6">
                        <div className="title-box-2 pt-4 pt-md-0">
                          <h5 className="title-left">
                            Get in Touch
                          </h5>
                        </div>
                        <div className="more-info">
                          <p className="lead">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis dolorum dolorem soluta quidem
                            expedita aperiam aliquid at.
                            Totam magni ipsum suscipit amet? Autem nemo esse laboriosam ratione nobis
                            mollitia inventore?
                          </p>
                          <ul className="list-ico">
                            <li><span className="ion-ios-location"></span> 329 WASHINGTON ST BOSTON, MA 02108</li>
                            <li><span className="ion-ios-telephone"></span> (617) 557-0089</li>
                            <li><span className="ion-email"></span> contact@example.com</li>
                          </ul>
                        </div>
                        <div className="socials">
                          <ul>
                            <li><a href=""><span className="ico-circle"><i className="ion-social-facebook"></i></span></a></li>
                            <li><a href=""><span className="ico-circle"><i className="ion-social-instagram"></i></span></a></li>
                            <li><a href=""><span className="ico-circle"><i className="ion-social-twitter"></i></span></a></li>
                            <li><a href=""><span className="ico-circle"><i className="ion-social-pinterest"></i></span></a></li>
                          </ul>
                        </div>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box">
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
      {/* <a href="#" className="switch-into">1</a> */}
      <div id="preloader"></div>
    </div>
  );
};

export default App;
