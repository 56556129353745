import React from 'react';
import { Button, Header, Icon, Image, Modal, Segment, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import cx from 'classnames';
import ReactPlayer from 'react-player';

const propTypes = {
  triggerComponent: PropTypes.object,
  title: PropTypes.string,
  header: PropTypes.string,
  mainPicture: PropTypes.string,
  modalContent: PropTypes.string,
  title: PropTypes.string,
  wrapPicture: PropTypes.bool,
}

const defaultProps = {
  triggerComponent: null,
  title: null,
  header: null,
  mainPicture: null,
  modalContent: null,
  title: null,
  wrapPicture: false,
}

const ScrollingModal = (props) => {
  
  const { triggerComponent, title, header, mainPicture, modalContent, wrapPicture } = props;
  const width = (window.screen.width >= 600) ? 30 : 100;
  return (
    <Modal open={true} size='small' className={cx('wrapped-image-modal', wrapPicture)}>
      <Modal.Header><a hrref={'https://www.facebook.com/KDO2020PRESI/?hc_ref=ARRWGFFXuaZUcHHCwo58aYQAbrq_hEdr7Tbf_0oYbyUkHexSrS7lGG6Kqo0M-exCg5k&ref=nf_target&__tn__=kC-R'}>Congrès d'investiture de KDO</a></Modal.Header>
      <Modal.Content>
        
          <Modal.Description className="scroll-modal-description-content">
          <ReactPlayer 
              width={`${width}%`}
              height="100%"
              style={{margin:'0 auto'}}
              url={`${process.env.REACT_APP_LIVE_EVENT_URL}`} />
            <div className="promolivelink">
              <div className="promolivelink2">
              <a hrref={'https://www.facebook.com/KDO2020PRESI/?hc_ref=ARRWGFFXuaZUcHHCwo58aYQAbrq_hEdr7Tbf_0oYbyUkHexSrS7lGG6Kqo0M-exCg5k&ref=nf_target&__tn__=kC-R'}>
                Suivez l'événement Live sur Facebook
              </a>
              </div>
              <div>
              <a hrref={'https://www.facebook.com/KDO2020PRESI/?hc_ref=ARRWGFFXuaZUcHHCwo58aYQAbrq_hEdr7Tbf_0oYbyUkHexSrS7lGG6Kqo0M-exCg5k&ref=nf_target&__tn__=kC-R'}>
                <img className="logopromos" src="assets/img/promotions/facebooklogo.png"/>
              </a>
              </div>
            </div>
          </Modal.Description>
        
      </Modal.Content>
    </Modal>
  )
}

ScrollingModal.propTypes = propTypes;
ScrollingModal.defaultProps = defaultProps;

export default ScrollingModal;